import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class InformationTechnology extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      {/* <h5>We Build everything</h5> */}
                      <h2 className="title">Information Technology</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src="https://via.placeholder.com/1200X800/444444.jpg"
                      alt="single-img-twelve"
                    />
                  </div> */}
                  <div className="ttm-service-description">
                    {/* <h4>About Consulting Services</h4> */}
                    <p>
                      ITOI DATA provides dynamic, business and technical
                      operations in commercial and public sector organizations.
                      We help the clients to develop their applications, and
                      infrastructure to better match the modern business
                      requirements. ITOI DATA replaces the wearied, monotonous
                      IT infrastructure with the latest ones, thereby helping
                      you to quickly move from yesterday to tomorrow. ITOI DATA
                      provides end-to-end consultancy services and support to
                      the clients.
                    </p>
                    <p>
                      ITOI DATA strongly condemns the act of snatching the
                      consultants in an illegitimate manner. To refrain this
                      act, ITOI DATA came up with the idea to monitor the
                      selected consultants’ calls with their consent. We would
                      highly appreciate it if you do not disclose the
                      information of the consultants for any further job
                      openings until he gets relieved from the hands of ITOI
                      DATA.
                    </p>
                    <p>
                      Considering the fact that the consultants we picked are
                      being taken away by some other consultancies, ITOI DATA
                      strongly suggests you to maintain the information
                      confidential. At any given scenario, we do not entertain
                      the unethical acts such as sharing the consultant’s
                      details on any public or private job portals on W2/1099
                      basis.
                    </p>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        {/* <h4>Fully Trained Employees</h4> */}
                        <p className="mb-35 res-991-mb-20">
                          For all due intents and purposes, ITOI DATA would like
                          to suggest that all the calls the consultants receive
                          would be monitored and recorded for quality purposes.
                          If you do not accept to any of these conditions, hard
                          luck for you! ITOI DATA would suggest you to abstain
                          from contacting our consultants.
                        </p>
                        {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-bgcolor-darkgrey">
                              Read more
                            </a> */}
                      </div>
                      {/* <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="img-fluid"
                            src="https://via.placeholder.com/480X550/444444.jpg"
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div> */}
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              {/* <div className="col-lg-3 widget-area">
                    <Sidebar />
                  </div> */}
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}


export default InformationTechnology;