import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class ConsultingServices extends Component {
    render() {
        return (
          <div className="site-main">
            <Header />
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
              <div className="container-xl">
                {/* row */}
                <div className="row">
                  <div className="col-lg-9 content-area order-lg-2">
                    {/* ttm-service-single-content-are */}
                    {/* ttm-service-single-content-are */}
                    <div className="ttm-service-single-content-area">
                      {/* section title */}
                      <div className="section-title with-desc text-left clearfix">
                        <div className="title-header">
                          {/* <h5>We Build everything</h5> */}
                          <h2 className="title">Consulting Service</h2>
                        </div>
                      </div>
                      {/* section title end */}
                      <div className="ttm-service-description">
                        <h4>About Consulting Services</h4>
                        <p>
                          We provide consulting across several specialty areas
                          and industry verticals, establishing success in both
                          private enterprise and the public sector. When you
                          work with Idea Integration professionals you have the
                          collective resources and commitment of our entire
                          consulting team selected from our nationwide
                          organization, enabling success in both private
                          enterprise and the public sector.
                        </p>
                        <p>
                          We are an innovative Consulting and Services company
                          focusing on implementing Software Engineering,
                          Enterprise solutions, E-Business, System Integration,
                          Technology consulting, and Offshore Delivery. Our goal
                          is to help you meet your business objectives and
                          maximize your return on investment in a fixed amount
                          of time at a fixed price and/or at Time & Material
                          Basis including On-Site and Off-site development.
                        </p>
                        <p>
                          We are also a staffing agency that offers flexible
                          staffing options. Many companies are leveraging the
                          services of staffing companies to gain access to
                          talent and adjust their workforce based on the
                          cyclical needs of their business. We offer Contract
                          Hiring, Direct Hiring, Flexible Hiring, and Permanent
                          Staffing options to quickly increase and decrease your
                          workforce.
                        </p>
                        {/* separator */}
                        <div className="separator">
                          <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                        </div>
                        {/* separator */}
                        <div className="row">
                          <div className="col-md-8">
                            {/* <h4>Fully Trained Employees</h4> */}
                            <p className="mb-35 res-991-mb-20">
                              We spur growth in the business by ensuring that
                              the clients’ applications integrate seamlessly
                              into their existing IT environment. We also enable
                              the clients to embrace new business models and
                              application investments to yield unimaginable
                              returns in the near future. We help the clients to
                              choose the most appropriate application suite to
                              enhance the productivity of the business. We also
                              ensure that there is tight integration in the
                              existing IT environment and the selected
                              application suite. ITOI DATA also helps the
                              clients in the effective implementation of the IT
                              applications. ITOI DATA makes a very good use of
                              the Application Development services,
                              Modernization, and Data Management to guide the
                              clients in:
                            </p>
                            {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-bgcolor-darkgrey">
                              Read more
                            </a> */}
                          </div>
                          {/* <div className="col-md-4">
                            <div className="ttm_single_image-wrapper res-767-mt-20">
                              <img
                                className="img-fluid"
                                src="https://via.placeholder.com/480X550/444444.jpg"
                                alt="single-img-sixteen"
                              />
                            </div>
                          </div> */}
                        </div>
                        {/* separator */}
                        <div className="separator">
                          <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                        </div>
                      </div>
                    </div>
                    {/* ttm-service-single-content-are end */}
                  </div>
                  {/* <div className="col-lg-3 widget-area">
                    <Sidebar />
                  </div> */}
                </div>
                {/* row end */}
              </div>
            </div>
          </div>
        );
    }
}


export default ConsultingServices;