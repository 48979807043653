import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class TalentManagement extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      {/* <h5>We Build everything</h5> */}
                      <h2 className="title">Talent Management</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src="https://via.placeholder.com/1200X800/444444.jpg"
                      alt="single-img-twelve"
                    />
                  </div> */}
                  <div className="ttm-service-description">
                    {/* <h4>About Consulting Services</h4> */}
                    <p>
                      From finding the right talent to matching the appropriate
                      job position, ITOI DATA does it all for you. We have an
                      elite set of consultants and the in-house “Research
                      Solution" that responds as quickly as they can, to meet
                      the requirements of the demanding business world. Our
                      consultants use various technologies such as web, social
                      media, cloud-based and mobile. Our effective talent
                      management strategies make sure that our performance is
                      aligned to execute the business deliverables.
                    </p>
                    <p>
                      ITOI DATA offers an incredible talent structure to help
                      the clients in identifying the right talent. We have built
                      our Talent Management Structure by doing profound
                      research, by adding our experience and by using the
                      Saratoga benchmarking data. Our compatible nature and
                      excellent technical skills aid the clients to achieve the
                      targets despite the technological transformation in the
                      business world. Our talent management strategy helps the
                      clients to select the bright and sophisticated
                      individuals. Our ideologies produce smarter, faster
                      results and help our clients stay ahead. We consistently
                      work with clients to offer better, innovative, and
                      contemporary methodologies and make sure that the company
                      is stepping forward in the right direction. We analyze and
                      identify risks, predict the returns, and develop
                      strategies to harvest greater profits.
                    </p>
                    <p>
                      To accomplish your goals and for promising careers at ITOI
                      DATA, meet us at <a href=":mailto">info@itoidata.com</a>
                    </p>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                    {/* separator */}
                    <div className="row">
                      <div className="col-md-8">
                        {/* <h4>Fully Trained Employees</h4> */}
                        <p className="mb-35 res-991-mb-20">
                          For all due intents and purposes, ITOI DATA would like
                          to suggest that all the calls the consultants receive
                          would be monitored and recorded for quality purposes.
                          If you do not accept to any of these conditions, hard
                          luck for you! ITOI DATA would suggest you to abstain
                          from contacting our consultants.
                        </p>
                        {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-bgcolor-darkgrey">
                              Read more
                            </a> */}
                      </div>
                      {/* <div className="col-md-4">
                        <div className="ttm_single_image-wrapper res-767-mt-20">
                          <img
                            className="img-fluid"
                            src="https://via.placeholder.com/480X550/444444.jpg"
                            alt="single-img-sixteen"
                          />
                        </div>
                      </div> */}
                    </div>
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                    </div>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              {/* <div className="col-lg-3 widget-area">
                    <Sidebar />
                </div> */}
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}


export default TalentManagement;