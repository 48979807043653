import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from 'react-router-dom';
import { services } from '../../data';

class Mobilemenu extends Component {
state = {
  collapse1: false,
  collapseID: ''
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
}

toggleSingleCollapse = collapseId => {
  this.setState({
    ...this.state,
    [collapseId]: !this.state[collapseId]
  });
}

render() {
  return (
    <Router>
      <MDBNavbar>
        <MDBHamburgerToggler
          id="hamburger1"
          onClick={() => this.toggleSingleCollapse("collapse1")}
        />
        <MDBCollapse isOpen={this.state.collapse1} navbar>
          <MDBNavbarNav left>
            <MDBNavItem active>
              <MDBNavLink
                active
                aria-current="page"
                to="/"
              >
                Home
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                active
                aria-current="page"
                to={process.env.PUBLIC_URL + "/aboutus_01"}
              >
                About us
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span>services</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/consulting_services"}
                  >
                    Consulting Services
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/products_and_solutions"}
                  >
                    Products and solutions
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/corproate_training"}
                  >
                    Corproate Training
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/talent_management"}
                  >
                    Talent Management
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    href={process.env.PUBLIC_URL + "/information_technology"}
                  >
                    Information Technology
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="/contact_01">Contact Us</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </Router>
  );
  }
}

export default Mobilemenu;