import React, { Component } from 'react';
import { MDBProgress } from 'mdbreact';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import Header from '../layout/Header';
import { services } from '../../data';

export class Aboutus_01 extends Component {
    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (
          <div className="site-main">
            <Header />
            {/* aboutus-section */}
            <section className="ttm-row aboutus-section clearfix">
              <div className="container-xl">
                <div className="row">
                  {/* row */}
                  <div className="col-lg-6">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>About us</h5>
                        <h2 className="title">
                          We are here to IT Solution with vast{" "}
                          <span>experience</span>
                        </h2>
                      </div>
                      <div className="title-desc">
                        <p>
                          We provide consulting across several specialty areas
                          and industry verticals, establishing success in both
                          private enterprise and the public sector. When you
                          work with Idea Integration professionals you have the
                          collective resources and commitment of our entire
                          consulting team selected from our nationwide
                          organization, enabling success in both private
                          enterprise and the public sector.
                        </p>
                        <p>
                          We are an innovative Consulting and Services company
                          focusing on implementing Software Engineering,
                          Enterprise solutions, E-Business, System Integration,
                          Technology consulting, and Offshore Delivery. Our goal
                          is to help you meet your business objectives and
                          maximize your return on investment in a fixed amount
                          of time at a fixed price and/or at Time & Material
                          Basis including On-Site and Off-site development.
                        </p>
                        <p>
                          We are also a staffing agency that offers flexible
                          staffing options. Many companies are leveraging the
                          services of staffing companies to gain access to
                          talent and adjust their workforce based on the
                          cyclical needs of their business. We offer Contract
                          Hiring, Direct Hiring, Flexible Hiring, and Permanent
                          Staffing options to quickly increase and decrease your
                          workforce.
                        </p>
                      </div>
                    </div>
                    {/* section title end */}
                    {/* row */}
                    <div className="row no-gutters mt-4">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          {services &&
                            services.slice(0, 3).map((item, index) => (
                              <>
                                <li key={index}>
                                  <i className="far fa-circle ttm-textcolor-skincolor" />
                                  <span className="ttm-list-li-content">
                                    {item}{" "}
                                  </span>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          {services &&
                            services.slice(3, 6).map((item) => (
                              <>
                                <li>
                                  <i className="far fa-circle ttm-textcolor-skincolor" />
                                  <span className="ttm-list-li-content">
                                    {item}{" "}
                                  </span>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>
                    </div>
                    {/* row END*/}
                  </div>
                  <div className="col-lg-6">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper text-sm-center">
                        <img
                          className="img-fluid"
                          src="images/ee/building.jpg"
                          title="single-img-seven"
                          alt="single-img-seven"
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                    </div>
                  </div>
                </div>
                {/* row end */}
              </div>
            </section>
            {/* aboutus-section end*/}
          </div>
        );
    }
}


export default Aboutus_01;