import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class CorproateTraining extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      {/* <h5>We Build everything</h5> */}
                      <h2 className="title">Corporate Training</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src="https://via.placeholder.com/1200X800/444444.jpg"
                      alt="single-img-twelve"
                    />
                  </div> */}
                  <div className="ttm-service-description">
                    {/* <h4>About Consulting Services</h4> */}
                    <p>
                      ITOI DATA consultancy offers an extensive training to the
                      consultants and thereafter provides the clients with the
                      industry-savvy consultants. We aspire to improve the
                      performance and enhance the productivity of the business.
                      ITOI DATA includes Scrum software methodology and the
                      important Agile methodologies to be used in the
                      organizations. By using these methodologies ITOI DATA
                      makes efforts to drive the transformation by providing
                      industry-leading consulting services. We help you to
                      refashion your strategies and procedures to improve the
                      global collaboration and maximize the value. Our approach
                      in providing top-notch consultants is innovative and
                      unique. Our methodologies bring out the best in the
                      individuals and help the clients reap incredible profits.
                    </p>
                    <p>We help the clients stay ahead by the following:</p>
                    <p>
                      We are also a staffing agency that offers flexible
                      staffing options. Many companies are leveraging the
                      services of staffing companies to gain access to talent
                      and adjust their workforce based on the cyclical needs of
                      their business. We offer Contract Hiring, Direct Hiring,
                      Flexible Hiring, and Permanent Staffing options to quickly
                      increase and decrease your workforce.
                    </p>
                    <h6>Progressive Elaboration:</h6>
                    <div className="row">
                      <div className="col-md-8">
                        {/* <h4>Fully Trained Employees</h4> */}
                        <p className="mb-35 res-991-mb-20">
                          This is one of the project management techniques
                          wherein the blueprint for the designated project is
                          constantly modified and improved as the project
                          unfolds and starts getting implemented. We help the
                          clients dissect the project in the right manner and
                          get more precise and clearer solutions.
                        </p>
                        {/* <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-bgcolor-darkgrey">
                              Read more
                            </a> */}
                      </div>
                    </div>
                    <h6>Regular Inspection:</h6>
                    <p>
                      We introspect our work on a regular basis to enhance the
                      work performance and thereby boosting the business
                      productivity.
                    </p>
                    <h6> Collaboration:</h6>
                    <p>
                      We streamline the workflows and offer seamless
                      collaboration among the employees and help them to be on
                      the same page. This leads to increased business
                      performance and aids in achieving the goals faster.
                    </p>
                    <h6>Adaptation:</h6>
                    <p>
                      Our technologies allow the clients to improvise their work
                      plan and to adopt the changes that take place in the
                      business environment. The notion behind the adaptation is
                      to help the clients meet the requirements of the
                      workforce.
                    </p>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              {/* <div className="col-lg-3 widget-area">
                    <Sidebar />
                  </div> */}
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}


export default CorproateTraining;