import React, { Component } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { services } from '../data';


const images = [
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg'
];

export class Home extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  // ITOI DATA, 13302, MAJESTIC CT, MOORPARK, CALIFORNIA, 93021
    render() {
      const { photoIndex, isOpen } = this.state;
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [{

            breakpoint: 1199,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
          },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 3
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        
        return (
          <div className="site-main">
            <Header />
            {/* home banner */}
            {/* <img src='images/site-inprogress.jpg' style={{width:'100%'}}/> */}
            <CarouselPage />
            {/* home banner end*/}
            {/* service-section */}

            {/* blog-section end */}
            <section className="ttm-row service-section position-relative z-1 clearfix">
              <div className="container-xl">
                <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
                  <div className="col-md-4">
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-system" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                      <div className="featured-content">
                        {/*  featured-content */}
                        <div className="featured-title">
                          {/*  featured-title */}
                          <h5>Trusted Services</h5>
                        </div>
                        <div className="featured-desc">
                          {/*  featured-desc */}
                          <p>We Are Trusted Our Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                          <i className="flaticon flaticon-clock" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                      <div className="featured-content">
                        {/*  featured-content */}
                        <div className="featured-title">
                          {/*  featured-title */}
                          <h5>24/7 Support</h5>
                        </div>
                        <div className="featured-desc">
                          {/*  featured-desc */}
                          <p>000 7845 114- 123 4567 890</p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */}
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="featured-icon">
                        {/*  featured-icon */}
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-developer" />
                          {/*  ttm-icon */}
                        </div>
                      </div>
                      <div className="featured-content">
                        {/*  featured-content */}
                        <div className="featured-title">
                          {/*  featured-title */}
                          <h5>Expert &amp; Professional</h5>
                        </div>
                        <div className="featured-desc">
                          {/*  featured-desc */}
                          <p>We Are Trusted Our Customers</p>
                        </div>
                      </div>
                    </div>
                    {/*  featured-icon-box END */}
                  </div>
                </div>
              </div>
            </section>
            {/* service-section end */}
            {/* aboutus-section */}
            <section className="ttm-row aboutus-section clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-6">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>About us</h5>
                        <h2 className="title">
                          We are here to IT Solution with vast{" "}
                          <span>experience</span>
                        </h2>
                      </div>
                      <div className="title-desc">
                        <p>
                          We provide consulting across several specialty areas
                          and industry verticals, establishing success in both
                          private enterprise and the public sector. When you
                          work with Idea Integration professionals you have the
                          collective resources and commitment of our entire
                          consulting team selected from our nationwide
                          organization, enabling success in both private
                          enterprise and the public sector.
                        </p>
                        <p>
                          We are an innovative Consulting and Services company
                          focusing on implementing Software Engineering,
                          Enterprise solutions, E-Business, System Integration,
                          Technology consulting, and Offshore Delivery. Our goal
                          is to help you meet your business objectives and
                          maximize your return on investment in a fixed amount
                          of time at a fixed price and/or at Time & Material
                          Basis including On-Site and Off-site development.
                        </p>
                        <p>
                          We are also a staffing agency that offers flexible
                          staffing options. Many companies are leveraging the
                          services of staffing companies to gain access to
                          talent and adjust their workforce based on the
                          cyclical needs of their business. We offer Contract
                          Hiring, Direct Hiring, Flexible Hiring, and Permanent
                          Staffing options to quickly increase and decrease your
                          workforce.
                        </p>
                      </div>
                    </div>
                    {/* section title end */}
                    {/* row */}
                    <div className="row no-gutters mt-4">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          {services &&
                            services.slice(0, 3).map((item, index) => (
                              <>
                                <li key={index}>
                                  <i className="far fa-circle ttm-textcolor-skincolor" />
                                  <span className="ttm-list-li-content">
                                    {item}{" "}
                                  </span>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          {services &&
                            services.slice(3, 6).map((item) => (
                              <>
                                <li>
                                  <i className="far fa-circle ttm-textcolor-skincolor" />
                                  <span className="ttm-list-li-content">
                                    {item}{" "}
                                  </span>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>
                    </div>
                    {/* row END*/}
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="fs-16 ttm-textcolor-darkgrey">
                      <span>
                        Call to ask{" "}
                        <a className="fw-500 ttm-textcolor-darkgrey">
                          <u>any question</u>
                        </a>
                      </span>
                      <strong className="fs-18">: +1 (469) 262- 5879 </strong>
                    </div>
                  </div>
                  <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src="./images/ee/side.jpg"
                          title="single-img-one"
                          alt="single-img-one"
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                      {/*featured-icon-box*/}
                      <div className="about-overlay-01">
                        <p className="mb-0">
                          American IT Solutions Association AIA.
                        </p>
                      </div>
                      {/* featured-icon-box end*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* aboutus-section end */}
            {/* history-section */}
            <section
              id="services"
              className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix"
            >
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {/* section title */}
                    <div className="section-title with-desc text-center clearfix">
                      <div className="title-header">
                        <h5>Our Services</h5>
                        <h2 className="title">
                          We run all kinds of services in form of{" "}
                          <span>Information &amp; Technologies</span>
                        </h2>
                      </div>
                    </div>
                    {/* section title end */}
                  </div>
                </div>
                <Slider
                  className="row slick_slider ttm-boxes-spacing-30px"
                  {...slick_slider}
                >
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">
                      {/* ttm-history-box-wrapper  */}
                      <div className="ttm-history-box-icon-wrapper">
                        {/* ttm-history-box-icon-wrapper  */}
                        {/*  featured-icon-box */}
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            {/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-business-and-finance" />
                              {/*  ttm-icon */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />
                      {/* ttm-history-box-border  */}
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title">
                          <h5>Information Technology</h5>
                        </div>
                        {/* historybox-title  */}
                        <div className="ttm-historybox-description">
                          {/* description  */}
                          ITOI DATA provides dynamic, business and technical
                          operations in commercial and public sector
                          organizations.
                        </div>
                        <a
                          href="/information_technology"
                          className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                        >
                          Read More <i className="ti ti-angle-double-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">
                      {/* ttm-history-box-wrapper  */}
                      <div className="ttm-history-box-icon-wrapper">
                        {/* ttm-history-box-icon-wrapper  */}
                        {/*  featured-icon-box */}
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            {/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-computer" />
                              {/*  ttm-icon */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />
                      {/* ttm-history-box-border  */}
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title">
                          <h5>Consulting Services</h5>
                        </div>
                        {/* historybox-title  */}
                        <div className="ttm-historybox-description">
                          {/* description  */}
                          We provide consulting across several specialty areas
                          and industry verticals, establishing success in both
                          private enterprise and the public sector.
                        </div>
                        <a
                          href="/consulting_services"
                          className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                        >
                          Read More <i className="ti ti-angle-double-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">
                      {/* ttm-history-box-wrapper  */}
                      <div className="ttm-history-box-icon-wrapper">
                        {/* ttm-history-box-icon-wrapper  */}
                        {/*  featured-icon-box */}
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            {/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-data" />
                              {/*  ttm-icon */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />
                      {/* ttm-history-box-border  */}
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title">
                          <h5>Products and Solutions</h5>
                        </div>
                        {/* historybox-title  */}
                        <div className="ttm-historybox-description">
                          {/* description  */}
                          We at ITOI DATA uses products that serve as an online
                          warehouse to store the information related to your
                          organization.
                        </div>
                        <a
                          href="/products_and_solutions"
                          className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                        >
                          Read More <i className="ti ti-angle-double-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">
                      {/* ttm-history-box-wrapper  */}
                      <div className="ttm-history-box-icon-wrapper">
                        {/* ttm-history-box-icon-wrapper  */}
                        {/*  featured-icon-box */}
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            {/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-global-1" />
                              {/*  ttm-icon */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />
                      {/* ttm-history-box-border  */}
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title">
                          <h5>Corporate Training</h5>
                        </div>
                        {/* historybox-title  */}
                        <div className="ttm-historybox-description">
                          {/* description  */}
                          ITOI DATA consultancy offers an extensive training to
                          the consultants and thereafter provides the clients
                          with the industry-savvy consultants.
                        </div>
                        <a
                          href="/corproate_training"
                          className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                        >
                          Read More <i className="ti ti-angle-double-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-box-col-wrapper">
                    <div className="ttm-history-box-wrapper">
                      {/* ttm-history-box-wrapper  */}
                      <div className="ttm-history-box-icon-wrapper">
                        {/* ttm-history-box-icon-wrapper  */}
                        {/*  featured-icon-box */}
                        <div className="featured-icon-box">
                          <div className="featured-icon">
                            {/*  featured-icon */}
                            <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                              <i className="flaticon flaticon-business-and-finance" />
                              {/*  ttm-icon */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ttm-history-box-border" />
                      {/* ttm-history-box-border  */}
                      <div className="ttm-history-box-details ttm-bgcolor-white">
                        <div className="ttm-historybox-title">
                          <h5>Talent Management</h5>
                        </div>
                        {/* historybox-title  */}
                        <div className="ttm-historybox-description">
                          {/* description  */}
                          From finding the right talent to matching the
                          appropriate job position, ITOI DATA does it all for
                          you. We have an elite set of consultants and the
                          in-house “Research Solution" that responds as quickly
                          as they can, to meet the requirements of the demanding
                          business world.
                        </div>
                        <a
                          href="/talent_management"
                          className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20"
                        >
                          Read More <i className="ti ti-angle-double-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </section>
            {/* history-section end*/}
            {/* zero-padding-section */}

            {/* zero-padding-section end */}

            {/* first-row-title-section */}
            <section className="ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes bg-img4 clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl">
                <div className="row">
                  {/* row */}
                  <div className="col-lg-8 offset-lg-2">
                    <div className="text-center">
                      {/* <div className="ttm-play-icon-btn mb-5">
                        <div className="ttm-play-icon-animation">
                          <a id="playbutton">
                            <div className="ttm-icon ttm-bgcolor-white ttm-textcolor-skincolor ttm-icon_element-size-sm rounded-circle">
                              <i className="fas fa-play" />
                            </div>
                          </a>
                          <div id="videobox">
                            <i id="close-btn" className="fa fa-times"></i>
                            <div id="video-wrapper">
                              <iframe
                                width="500"
                                height="500"
                                src="https://www.youtube.com/embed/7e90gBu4pas"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* section-title */}
                      <div className="section-title with-desc text-center clearfix">
                        <div className="title-header">
                          <h2 className="title">
                            We Provide Outsourced IT Services For Small &amp;{" "}
                            <span className="ttm-textcolor-white">
                              Mid-Sized Business
                            </span>
                          </h2>
                        </div>
                        <div className="title-desc">
                          Appropriate for your specific business, making it easy
                          for
                          <br /> you to have quality IT services. What We Do
                        </div>
                      </div>
                      {/* section-title end */}
                      <div className="mt-5">
                        <a
                          href="#services"
                          className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                        >
                          More Services
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* first-row-title-section end*/}
            {/* testimonial-section */}
            <section className="ttm-row zero-padding-section clearfix">
              <div className="container-xl">
                <div className="row no-gutters mt_100 res-991-mt-0">
                  <div className="col-md-6">
                    <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                      <div className="layer-content">
                        {/* layer-content */}
                        <div className="spacing-2">
                          {/* spacing-2 */}
                          {/* section title */}
                          <div className="section-title ttm with-desc clearfix">
                            <div className="title-header">
                              <h5>Our Vision</h5>
                              <h2 className="title">
                                We help you to grow your business{" "}
                                <span className="ttm-textcolor-white">
                                  Exponentially
                                </span>
                              </h2>
                            </div>
                            <p>
                              ITOI Data is quite passionate about extending the
                              services to the clients from various sectors and
                              ensure that each client is provider with the best
                              solutions to bring revolutionary changes in the
                              business world. Our robust delivery structure and
                              the prominent methodologies help the clients to
                              achieve the goals faster than ever before. For any
                              further information about us or our service, our
                              dedicated team is readily available to answer all
                              your questions.
                            </p>
                          </div>
                        </div>
                        {/* section title end */}
                        {/* spacing-2 END */}
                      </div>
                      {/* layer-content END */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                        <div className="ttm-bg-layer-inner" />
                      </div>
                      <div className="layer-content">
                        <div className="testimonial-slide style1">
                          <Slider
                            className="row slick_slider"
                            arrows={false}
                            dots={true}
                          >
                            <div className="col-lg-12">
                              <div className="testimonials text-center">
                                <div className="testimonial-content">
                                  {/* testimonials-content */}
                                  <div className="testimonial-avatar">
                                    <div className="testimonial-img">
                                      {/* testimonials-img */}
                                      <img
                                        className="img-center"
                                        alt="testimonial-img"
                                        src="images/ee/myemoji.png"
                                      />
                                    </div>
                                  </div>
                                  <blockquote>
                                    Our team discussed every single detail to
                                    make sure Creatives Planet is the most
                                    versatile and unique theme created so far.
                                  </blockquote>
                                  <div className="ttm-ratting-star">
                                    <i className="fa fa-star" />
                                    &nbsp;
                                    <i className="fa fa-star" />
                                    &nbsp;
                                    <i className="fa fa-star" />
                                    &nbsp;
                                    <i className="fa fa-star" />
                                    &nbsp;
                                    <i className="fa fa-star" />
                                  </div>
                                  <div className="testimonial-caption">
                                    {/* testimonials-caption */}
                                    <h6>Venkat Jammalamudi</h6>
                                    <label>Senior Software Engineer</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* testimonial-section end */}
          </div>
        );
    }
}


export default Home;
