import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Header from '../layout/Header';

export class ProductsandSolutions extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                {/* ttm-service-single-content-are */}
                {/* ttm-service-single-content-are */}
                <div className="ttm-service-single-content-area">
                  {/* section title */}
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header">
                      {/* <h5>We Build everything</h5> */}
                      <h2 className="title">Products and Solutions</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  {/* <div className="ttm_single_image-wrapper mb-35">
                    <img
                      className="img-fluid"
                      src="https://via.placeholder.com/1200X800/444444.jpg"
                      alt="single-img-twelve"
                    />
                  </div> */}
                  <div className="ttm-service-description">
                    {/* <h4>About Consulting Services</h4> */}
                    <p>
                      We at ITOI DATA uses products that serve as an online
                      warehouse to store the information related to your
                      organization. They aid in streamlining all the workflows
                      of the organization and in enhancing the pace of the
                      organization’s work. These products have attributes that
                      help in the following:
                    </p>
                    <h6>Marketing and Sales:</h6>
                    <p>
                      Supports the smooth flow of marketing processes by
                      streamlining the workflows to match the client needs. This
                      product line helps by using the resource skill matrix, and
                      by tracking the entire hiring process of the consultants
                      across various sales phases.
                    </p>
                    <h6>Recruitment Workbench:</h6>
                    <p>
                      This product line helps the organizations to embrace the
                      top talent.
                    </p>
                    <h6>Employee Workbench:</h6>
                    <p>
                      Employee workbench aids in the resource management
                      process. This attribute manages all the processes such as
                      invoicing, billing, payroll management and many more.
                    </p>
                  </div>
                </div>
                {/* ttm-service-single-content-are end */}
              </div>
              {/* <div className="col-lg-3 widget-area">
                    <Sidebar />
                  </div> */}
            </div>
            {/* row end */}
          </div>
        </div>
      </div>
    );
  }
}


export default ProductsandSolutions;